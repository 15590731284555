<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="block">
              <img class="error_img" src="@/assets/not_found.png" alt="404">
            </div>
            <div class="block mt-6">
              <h2 class="has-text-centered">
                ¡Error 404!
              </h2>
            </div>
            <div class="block mb-6">
              <h1 class="has-text-centered">
                Página no encontrada
              </h1>
            </div>
            <div class="block has-text-centered">
              <router-link class="button button_micro_dark" :to="{ name: 'dashboard' }">
                VOLVER A INICIO
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ErrorPage'
}
</script>

<style lang="scss" scoped>
  .hero {
    background-color: #f7f7f7;
  }

  .hero.is-fullheight .hero-body {
    align-items: center;
  }

  .error_img {
    width: 190px;
    margin: auto;
    display: block;
  }

  h1 {
    font-size: 22px;
    font-style: italic;
    line-height: 1.14;
    color: #b5b5b5;
  }

  h2 {
    font-size: 44px;
    font-weight: bold;
    line-height: 1.18;
    color: #262626;
  }
</style>
